<template>
      <el-dialog class="" destroy-on-close :visible.sync="dialogVisible" :width="obejctData.width+'%'" :title="obejctData.title" :type="obejctData.type">
        <!-- 显示网页 -->
        <template v-if="obejctData.type === 1">
            <iframe :src="obejctData.url" frameborder="0"></iframe>
        </template>
         <!-- 显示选人 -->
        <template v-if="obejctData.type === 2">
            <div class="center">
          <p>my name is popup</p>
        </div>
        </template>
         <!-- 显示swiper -->
        <template v-if="obejctData.type === 3">
            <div class="center">
          <p>my name is popup</p>
        </div>
        </template>
        <span slot="footer">
            <el-button @click="dialogVisible = false">取消</el-button>
        </span>
      </el-dialog>
  </template>
   
  <script>
  export default {
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      //传递的参数信息
      obejctData: {
        type: Object,
        default: () => {}
      },
     
      
    },
    computed: {
      dialogVisible: {
        get() {
          return this.visible
        },
        set(val) {
          this.$emit('updateVisible', val)
        }
      }
    },
    created() {
      // console.log(this.obejctData)
    }
  }
  </script>
<style lang="scss" scoped>
::v-deep .el-dialog {
		margin-top: 0 !important;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: #F9F9F8 !important;
	}
</style>
  