<template>
  <div class="container">
    <div class="header">
      <div class="logo-box">
        <img width="600px" height="auto" src="https://oss.culturalcloud.net/furong/202402/01223856x9hz.png" alt="" />
      </div>
      <div style="position: absolute; right: 30px; bottom: 30px; z-index: 99">
        <el-button type="danger" size="mini" @click="logOut()">退出登陆</el-button>
        <el-button type="primary" size="mini" @click="fixedPw = true">修改密码</el-button>
      </div>
      <div class="time-weather">
        <div class="time" style="display: flex; align-items: center">
          <div style="margin-right: 50px; cursor: pointer">
            <span class="full-screen" v-if="!isFullScreen" @click="toggleFullscreen">
              <i class="el-icon-full-screen" />&nbsp;&nbsp;点击全屏
            </span>
            <span class="full-screen" v-else @click="toggleFullscreen">
              <i class="el-icon-copy-document" />&nbsp;&nbsp;退出全屏
            </span>
          </div>
          <div>
            <span style="font-size: 26px">{{ dateDay }} {{ dateWeek }}</span><br /><span style="font-size: 22px">{{
          dateYear }}</span>
          </div>
        </div>
        <div class="weather">
          <div style="display: flex; align-items: center">
            <div>
              <i style="font-size: 26px" class="el-icon-cloudy"></i>
            </div>
            <div style="padding-left: 5px" v-if="weather">
              {{ weather.weather }}<br />{{
          weather.temperature.replace("/", "-")
        }}
            </div>
          </div>
          <div style="display: flex; align-items: center">
            <div>
              <i style="font-size: 22px" class="el-icon-date"></i>
            </div>
            <div style="padding-left: 5px">
              {{ getLunarDay.lunarYear }}<br />
              {{ getLunarDay.dateStr }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="body">
      <div class="menu-box">
        <div class="menu">
          <div class="menu-item" @click="toDetail(item)" v-for="(item, index) in menuList" :key="index">
            <img :src="item.titleImg" />
          </div>
        </div>
      </div>
      <div class="amb">
        <div class="top">
          <!-- <img @click="$router.push('/img?imgUrl=' + 'https://oss.culturalcloud.net/furong/202210/30214147cfue.png')"
						src="https://oss.culturalcloud.net/furong/202210/29232056l4ai.png" alt=""> -->
          <img @click="$router.push('/culture')" src="https://oss.culturalcloud.net/furong/202210/29232056l4ai.png"
            alt="" />

          <img @click="showImg('长中期经营计划')" src="https://oss.culturalcloud.net/furong/202407/131023202zdl.jpg" alt="" />
          <img @click="showImg('经营远景计划')"  src="https://oss.culturalcloud.net/furong/202210/29232204d3pp.png" alt="" />
        </div>
        <div class="ellipse">
          <div style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 20;
            ">
            <img src="https://oss.culturalcloud.net/furong/202210/29233513xkw8.png" alt="" />
          </div>
          <div class="p" style="">
            <div style="
                font-size: 28px;
                top: 50%;
                left: 0;
                transform: translate(-50%, -50%);
              ">
              P5<br />(新生部门)
            </div>
            <div style="
                font-size: 28px;
                top: 23%;
                left: -6%;
                transform: scale(0.75);
              " @click="toFiles('P4')">
              P4
            </div>
            <div style="font-size: 28px; top: 9%; left: 1%; transform: scale(0.65)" @click="toFiles('客户名单')">
              P3<br />客户名单
            </div>
            <div style="font-size: 28px; top: 0; left: 11%; transform: scale(0.55)" @click="toFiles('政策文件')">
              P2<br />政策文件
            </div>
            <div style="
                font-size: 28px;
                top: -5%;
                left: 21%;
                transform: scale(0.45);
              " @click="toStep('杜国辉')">
              P1<br />杜总
            </div>
          </div>
          <img style="
              position: absolute;
              left: 55.5%;
              transform: translateX(-50%);
              height: 50%;
            " src="https://oss.culturalcloud.net/furong/202306/12100040r2sl.png" alt="" />
          <div class="branch">
            <div style="left: 58%; top: 15%" @click="toDetail(businessPlanning)">
              经营企划
            </div>
            <div style="left: 50%; top: 30%; transform: translateX(-50%)" @click="toFiles('公司年度经营计划')">
              公司年度经营计划
            </div>
            <div style="left: 61.5%; top: 45%" @click="toFiles('经营管理')">
              经营管理
            </div>
            <div class="circle" style="left: 27.5%; top: 65%" @click="toFiles('人事')">
              人事
            </div>
            <div class="circle" style="left: 38.9%; top: 65%" @click="toFiles('财务')">
              财务
            </div>
            <div class="circle" style="
                left: 54.9%;
                top: 65%;
                background-color: #5263a3;
                color: #fff;
                border: none;
              " @click="toWork('技术')">
              技术
              <div class="fifthg-fandata" @click.stop="link('http://data.wantuyun.cn/')">
                5 G<br />展示
              </div>
            </div>
            <div class="circle" style="left: 66%; top: 65%" @click="toWork('售后')">
              售后
            </div>
            <div class="circle" style="left: 77.5%; top: 65%" @click="investmentShow = true">招商</div>
          </div>
          <div style="right: -8%; top: 50%; transform: translateY(-110%)" class="centre" @click="toStep('高飞')">
            SAU<br />费用中心<br />高飞
          </div>
          <div style="right: -8%; top: 50%; transform: translateY(10%)" class="centre" @click="toStep('金诚')">
            SBU<br />利润中心<br />金诚
          </div>
          <div class="department-box">
            <div class="department" style="width: 220px; height: 220px; left: 7%">
              <img src="https://oss.culturalcloud.net/furong/202210/301638168ao9.png" alt="" />
              <div class="minister" style="top: 22%" @click="toStep('金诚')">
                悦艺部长<br />
                金诚
              </div>
              <div class="minister" style="
                  position: absolute;
                  left: 39px;
                  top: 63%;
                  padding-top: 0;
                  width: 70px;
                  height: 70px;
                " @click="toWork('售后')">
                叶晓宇
              </div>
              <div class="staff" @click="toStep('刘洋')">刘洋</div>
              <div class="staff" @click="toStep('何银珠')" style="top: 47%; left: 68%">
                何银珠
              </div>
              <div class="staff" @click="toStep('胡杨')" style="top: 68%; left: 52%">
                胡杨
              </div>
            </div>
            <div class="department" style="
                border-color: #4c89c0;
                left: 50%;
                transform: translateX(-50%);
              ">
              <img src="https://oss.culturalcloud.net/furong/202210/30164446scwe.png" alt="" />
              <div class="minister" @click="toWork('悦读')" style="background-color: #699ccc">
                悦读副部长<br />
                杜欣毅
              </div>
              <div class="staff" style="
                  background-color: #699ccc;
                  color: #ffffff;
                  left: 50%;
                  top: 60%;
                  width: 70px;
                  height: 70px;
                " @click="toStep('王来峰')">
                王来峰
              </div>
              <div class="staff" style="
                  background-color: #699ccc;
                  color: #ffffff;
                  top: 25%;
                  width: 70px;
                  height: 70px;
                " @click="toStep('张友谊')">
                张友谊
              </div>
              <div class="staff" style="
                  background-color: #699ccc;
                  color: #ffffff;
                  left: 25%;
                  top: 75%;
                  padding: 5px;
                  width: 50px;
                  height: 50px;
                ">
                校园书吧
              </div>
            </div>
            <div class="department" style="border-color: #46a37f; left: 71.5%">
              <img src="https://oss.culturalcloud.net/furong/202210/30164809nxrc.png" alt="" />
              <div class="minister" @click="toStep('金诚')" style="background-color: #40c291">
                悦动副部长<br />
                金诚
              </div>
              <div class="staff" style="
                  background-color: #40c291;
                  color: #ffffff;
                  left: 55%;
                  top: 30%;
                  width: 90px;
                  height: 90px;
                ">
                汤光武
              </div>
            </div>
            <div class="department" style="border-color: #cd8243; top: 35%; left: 25.5%">
              <img src="https://oss.culturalcloud.net/furong/202308/21172309npnb.png" alt="" />

              <div class="minister" @click="toStep('唐一飞')" style="background-color: #dfa16e; padding-top: 0">
                亮旗<br />
                唐一飞
              </div>
              <div class="staff" style="
                  background-color: #dfa16e;
                  color: #ffffff;
                  width: 70px;
                  height: 70px;
                ">
                欧阳宇枫
              </div>
              <div class="staff" @click="toStep('曾宪军')" style="
                  top: 60%;
                  left: 45%;
                  background-color: #dfa16e;
                  color: #ffffff;
                  width: 70px;
                  height: 70px;
                ">
                曾宪军
              </div>
            </div>
            <div class="department" style="border-color: #c13130; top: 35%; left: 56%">
              <img src="https://oss.culturalcloud.net/furong/202306/12103345f0gt.png" alt="" />

              <div class="minister" @click="toStep('杜国辉')"
                style="background-color: #d64544; width: 100px; height: 100px">
                悦耀(教)部长<br />
                杜总
              </div>
              <div class="staff" @click="toStep('刘亚飞')" style="background-color: rgb(193, 49, 48); color: #ffffff">
                刘亚飞
              </div>
              <!-- <div class="staff" style="background-color: rgb(193, 49, 48);color: #ffffff;top:50%;width: 70px;height: 70px;"
								>亮旗 <br/> 唐一飞</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :destroy-on-close="true" class="img-window" :visible.sync="imgWindow" width="80%">
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="(item, index) in showImgs" :key="index">
          <img :src="item" style="width: 100%; height: 100%; object-fit: cover" alt="" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev" style=""></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </el-dialog>
    <!-- 打开经营管理 -->
    <el-dialog :destroy-on-close="true" class="img-window" :visible.sync="mange" width="90%">
      <iframe :src="fileUrl" frameborder="0" style="height: 80vh; width: 100%"></iframe>
    </el-dialog>
    <el-dialog title="请选择员工" :visible.sync="dialogVisible" width="50%">
      <el-button icon="el-icon-user-solid" type="primary" size="default" @click="toRoute(item)"
        v-for="item in staffList" :key="item.userId">{{ item.nickName }}</el-button>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <!-- <el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
      </span>
    </el-dialog>
    <el-dialog title="菜单" :visible.sync="center" width="30%">
      <div style="display: flex; flex-wrap: wrap; justify-content: space-around">
        <div class="role" v-for="(item, index) in centerList" :key="index" @click="toPage(item)">
          <i :class="item.icon" style="font-size: 30px"></i>
          <span>{{ item.title }}</span>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="center = false">取消</el-button>
        <!-- <el-button type="primary" @click="">OK</el-button> -->
      </span>
    </el-dialog>

    <el-dialog title="招商" :visible.sync="investmentShow" width="30%">
      <el-button type="primary" @click="toStep('陈茁')">陈茁</el-button>
      <span slot="footer">
        <el-button @click="investmentShow = false">取消</el-button>
      </span>
    </el-dialog>

    <el-dialog title="修改密码" :visible.sync="fixedPw" width="30%">
      <el-form :model="pass" ref="passForm" :rules="passRule">
        <el-form-item label="当前密码" label-width="120px" prop="old">
          <el-input v-model="pass.old" placeholder="请输入当前密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码" label-width="120px" prop="new">
          <el-input v-model="pass.new" placeholder="请输入新密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="changePwd">确 定</el-button>
      </span>
    </el-dialog>
    <!-- <PopUp v-if="visible" :visible.sync="visible" :obejct-data="obejctData" @updateVisible="updateVisibleId" /> -->
  </div>
</template>

<script>
import PopUp from "@/components/popup";
import { getWeather, getDept, getUser, limit, updateUserPwd } from "@/api";
import { getLunar } from "chinese-lunar-calendar";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "index",
  components: {
    swiper,
    swiperSlide,
    PopUp,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  data() {
    return {
      passRule: {
        old: [
          {
            required: true,
            message: "请输入当前密码",
            trigger: "blur",
          },
        ],
        new: [
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur",
          },
        ],
      },
      pass: {},
      investmentShow:false,

      fixedPw: false,
      visible: false,
      obejctData: {
        title: "123",
      },
      swiperOption: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      center: false,
      mange: false,
      fileUrl: "",
      centerList: [],
      time: "",
      dialogVisible: false,
      staffList: [],
      weather: "",
      businessPlanning: {
        title: "经营企划",
        menu: [
          {
            icon: "el-icon-s-data",
            title: "经营企划",
            url: "/files/21",
          },
          {
            icon: "el-icon-folder-opened",
            title: "宣传资料",
            url: "/files/22",
          },
          {
            icon: "el-icon-files",
            title: "活动策划",
            url: "/files/23",
          },
        ],
      },
      menuList: [
        {
          title: "公司文化",
          titleImg:
            "https://oss.culturalcloud.net/furong/202210/29213328jsre.png",
          page: "/culture",
        },
        {
          title: "经营会计",
          titleImg:
            "https://oss.culturalcloud.net/furong/202212/18215914dyae.png",
          menu: [
            {
              icon: "el-icon-s-order",
              title: "收款表",
              url: "/files/30",
            },
          ],
          showImg: true,
        },
        {
          title: "事业组织",
          titleImg:
            "https://oss.culturalcloud.net/furong/202212/18215801kxhy.png",
          // menu: [{
          // 	icon: 'el-icon-s-data',
          // 	title: '项目分析',
          // 	url: '/sbu'
          // }, {
          // 	icon: 'el-icon-folder-opened',
          // 	title: '月会材料',
          // 	url: '/files/19'
          // }, {
          // 	icon: 'el-icon-files',
          // 	title: '投标文件',
          // 	url: '/files/20'
          // }],
          showImg: true,
        },
      ],
      imgWindow: false,
      imgList: [
        {
          title: "经营远景计划",
          imgs: [
            "https://oss.culturalcloud.net/furong/202403/01160921eka0.png",
            "https://oss.culturalcloud.net/furong/202403/01160958f3m9.png",
            "https://oss.culturalcloud.net/furong/202403/01161015cm3e.png",
            "https://oss.culturalcloud.net/furong/202210/30221317bu6w.png",
            "https://oss.culturalcloud.net/furong/202210/30221337nwui.png",
            "https://oss.culturalcloud.net/furong/202210/30221354itcm.png",
          ],
        },
        {
          title: "长中期经营计划",
          imgs: [
            "https://oss.culturalcloud.net/furong/202403/01161252z6ne.png",
            "https://oss.culturalcloud.net/furong/202210/30222103ltvz.png",
            "https://oss.culturalcloud.net/furong/202210/30222104ogr3.png",
            "https://oss.culturalcloud.net/furong/202210/30222104tl15.png",
            "https://oss.culturalcloud.net/furong/202210/30222108humy.png",
            "https://oss.culturalcloud.net/furong/202210/30222109i3ig.png",
          ],
        },
        {
          title: "经营管理",
          imgs: [
            "https://oss.culturalcloud.net/furong/202210/30222109i3ig.png",
          ],
        },
        {
          title: "经营会计",
          imgs: [
            "https://oss.culturalcloud.net/furong/202403/01163759ssfr.png",
            "https://oss.culturalcloud.net/furong/202403/01163759hak6.png",
            "https://oss.culturalcloud.net/furong/202403/01163759a7xj.png",
            "https://oss.culturalcloud.net/furong/202403/011637597ovu.png",
            "https://oss.culturalcloud.net/furong/202403/01163759gfla.png",
            "https://oss.culturalcloud.net/furong/202403/01163759lavn.png",
            "https://oss.culturalcloud.net/furong/202403/021614309ufe.png",
            "https://oss.culturalcloud.net/furong/202403/01163759wlho.png",
            "https://oss.culturalcloud.net/furong/202403/01163759t3if.png",
            "https://oss.culturalcloud.net/furong/202403/011637598a59.png",
          ],
        },
        {
          title: "事业组织",
          imgs: [
            "https://oss.culturalcloud.net/furong/202403/08225818jbu4.png",
            "https://oss.culturalcloud.net/furong/202403/08225818pr1c.png",
            "https://oss.culturalcloud.net/furong/202403/08225818d4b9.png",
            "https://oss.culturalcloud.net/furong/202403/08225819qkj8.png",
            "https://oss.culturalcloud.net/furong/202403/082258219ymj.png",
            "https://oss.culturalcloud.net/furong/202403/082258214agp.png",
            "https://oss.culturalcloud.net/furong/202403/08225821vglu.png",
            "https://oss.culturalcloud.net/furong/202403/08225822e1xl.png",
            "https://oss.culturalcloud.net/furong/202403/13164834l0jx.png",
            "https://oss.culturalcloud.net/furong/202403/08225822z6up.png",
            "https://oss.culturalcloud.net/furong/202403/08225823942v.png",
          ],
        },
      ],
      showImgs: [],
      timing: null,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],

      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      date: new Date().getDate(),
      getLunarDay: {},
      isFullScreen: false,
    };
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    // 全屏方法
    toggleFullscreen() {
      let _this = this;
      let el = document.documentElement;
      if (document.fullscreenElement === null) {
        this.isFullScreen = true;
        _this.openFullscreen(el);
      } else {
        this.isFullScreen = false;
        _this.quitFullscreen();
      }
    },
    openFullscreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        // IE11
        element.msRequestFullscreen();
      }
    },
    quitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    // 全屏方法end

    changePwd() {
      this.$refs["passForm"].validate((valid) => {
        if (valid) {
          updateUserPwd(this.pass.old, this.pass.new).then((response) => {
            // this.$modal.msgSuccess("密码修改成功");
            this.$message({
              message: "密码修改成功",
              type: "success",
            });
            this.fixedPw = false;
            this.pass = {};
          });
        }
      });
    },
    link(url) {
      open(url);
    },
    //退出登陆
    logOut() {
      this.$confirm("确定退出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.Storage.clear();
        this.$router.push("/login");
      });
    },
    openManer(url) {
      this.fileUrl = url;
      this.mange = true;
    },
    toDetail(item) {
      // console.log(item);
      if (item.page) {
        this.$router.push(item.page);
      } else if (item.showImg) {
        this.showImg(item.title);
      } else {
        this.centerList = item.menu;
        this.center = true;
      }
    },
    toWork(value) {
      getDept({
        deptName: value,
      }).then((res) => {
        // console.log(res);
        const { data } = res;
        // console.log(data[0].nickName);
        if (data.length > 1) {
          //多人
          this.staffList = data;
          this.dialogVisible = true;
        } else {
          //单人
          this.toRoute(data[0]);
        }
      });
    },
    toFiles(name) {
      getUser({
        name: name,
      }).then((res) => {
        // console.log(res);
        // this.toRoute(data[0])
        this.$router.push("files/" + res.data.userId);
      });
    },
    toStep(name) {
      getUser({
        name: name,
      }).then((res) => {
        // console.log(res);
        // this.toRoute(data[0])
        this.toRoute(res.data);
      });
    },
    toRoute(data) {
      this.$router.push({
        path: "/step/one/" + data.userId + "/" + data.nickName,
      });
    },
    formatTime(time, fmt) {
      if (!time) return "";
      else {
        const date = new Date(time);
        const o = {
          "M+": date.getMonth() + 1,
          "d+": date.getDate(),
          "H+": date.getHours(),
          "m+": date.getMinutes(),
          "s+": date.getSeconds(),
          "q+": Math.floor((date.getMonth() + 3) / 3),
          S: date.getMilliseconds(),
        };
        if (/(y+)/.test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            (date.getFullYear() + "").substr(4 - RegExp.$1.length)
          );
        for (const k in o) {
          if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(
              RegExp.$1,
              RegExp.$1.length === 1
                ? o[k]
                : ("00" + o[k]).substr(("" + o[k]).length)
            );
          }
        }
        return fmt;
      }
    },
    timeFn() {
      this.dateDay = this.formatTime(new Date(), "HH: mm: ss");
      this.dateYear = this.formatTime(new Date(), "yyyy年MM月dd日");
      this.dateWeek = this.weekday[new Date().getDay()];
      this.timing = setInterval(() => {
        this.dateDay = this.formatTime(new Date(), "HH: mm: ss");
        this.dateYear = this.formatTime(new Date(), "yyyy年MM月dd日");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    async toPage(url) {
      // console.log(url);
      if (url.limitUserId) {
        //查看是否有权限打开
        let allow = false; //默认不允许打开
        await limit({
          userId: url.limitUserId,
        }).then((res) => {
          if (res.code == 200) {
            allow = true;
          }
        });
        if (!allow) {
          return;
        }
      }
      if (url.project) {
        this.center = false;
        this.openManer(url.project);
        // this.
      } else {
        this.$router.push(url.url);
      }
      // if(url)
    },
    haha(url) { },
    showImg(title) {
      // if()
      this.imgList.forEach((item) => {
        if (item.title == title) this.showImgs = item.imgs;
      });
      this.imgWindow = true;
    },
    updateVisibleId(val) {
      this.visible = val;
    },
  },
  created() {
    getWeather().then((res) => {
      // console.log(res);
      this.weather = res.data.result.future[0];
    });
    setTimeout(() => {
      //弹出
      this.visible = true;
      this.obejctData = {
        type: 1,
        width: 90,
        title: "111",
        data: [],
        url: "http://onlinefile.wantuyun.cn/onlinePreview?url=aHR0cHM6Ly9yZWFkLmN1bHR1cmFsY2xvdWQubmV0L3d5eS1tZWV0aW5nLzIwMjIxMS8xMDExMzk0MGdtaGMucHB0eA%3D%3D",
      };
    }, 1000);
  },
  mounted() {
    this.timeFn();
    this.getLunarDay = getLunar(this.year, this.month, this.date);
  },
};
</script>

<style lang="scss" scoped>
.container {
  // width: 100vw;
  height: 100vh;
  background-image: linear-gradient(#fffdfb, #fff3ea, #ffe5d1);
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .logo-box {
    // position: absolute;
    // top: 35px;
    // left: 25px;
    padding-left: 35px;
    padding-top: 25px;
  }

  .time-weather {
    display: flex;
    flex-direction: column;
    // justify-content: flex-end;
    align-items: flex-end;
    padding: 10px 30px 0 0;
    color: #b77f51;
    font-weight: bold;

    .weather {
      width: 220px;
      // box-shadow: 4px 7px 10px -4px rgba(0, 0, 0, .4);
      margin-top: 20px;
      font-family: "黑体";
      font-size: 16px;
      // background-image: linear-gradient(90deg, #FFF8F4, #FCEEE4);
      padding: 5px 20px;
      box-sizing: content-box;
      display: flex;
      // align-content: flex-end;
      justify-content: space-between;
    }
  }
}

.body {
  width: 100%;
  padding: 80px 100px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  .menu-box {
    .menu {
      .menu-item {
        width: 363px;
        height: 248px;
        margin-bottom: 10px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }

        .PM-box {
          width: 80px;
          height: 80px;
          background: linear-gradient(135deg, #17ead9, #6078ea);
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-size: 20px;
          position: absolute;
          right: 0;
          top: 0;
          cursor: pointer;
        }
      }
    }
  }

  .amb {
    width: 1300px;
    height: 100%;

    .top {
      margin: 0 auto;
      width: 850px;
      display: flex;
      justify-content: space-between;
      position: relative;

      img {
        z-index: 10;
        cursor: pointer;
      }
    }

    .top:before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #cf9f57;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .ellipse {
      position: relative;
      margin: 40px auto 0;
      width: 1080px;
      height: 715px;
      border-radius: 50%;
      border: 2px solid #98938e;
      background-color: #fef4ea;

      .p {
        div {
          width: 200px;
          height: 135px;
          background-image: url(https://oss.culturalcloud.net/furong/202210/30133715ad0k.png);
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          position: absolute;
          z-index: 999;
          cursor: pointer;
        }

        img {
          position: absolute;
        }
      }
    }

    .branch {
      width: 100%;
      height: 50%;
      position: relative;

      .circle {
        width: 70px !important;
        height: 70px !important;
        padding: 0;

        .fifthg-fandata {
          text-align: center;
          width: 50px;
          height: 50px;
          border-radius: 50px;
          padding: 0;
          top: 100%;
          font-size: 14px;
          color: #000000;
        }
      }

      div {
        cursor: pointer;
        position: absolute;
        font-size: 26px;
        font-family: "黑体";
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 30px;
        background-color: #fef9f4;
        border-radius: 40px;
        border: 2px solid #98938e;
      }
    }

    .centre {
      z-index: 999;
      position: absolute;
      width: 175px;
      height: 175px;
      border-radius: 50%;
      background-color: #ffd9bd;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 32px;
      font-family: "黑体";
      font-weight: bold;
      cursor: pointer;
    }

    .department-box {
      width: 100%;
      height: 50%;
      position: relative;

      .department {
        width: 200px;
        height: 200px;
        background-color: pink;
        border-radius: 50%;
        background-color: #fffdfb;
        border: 2px solid #d1c46c;
        position: absolute;
        text-align: center;
        top: 15px;
        left: 10%;

        img {
          width: 95px;
          margin-top: 10px;
        }

        .minister {
          cursor: pointer;
          width: 90px;
          height: 90px;
          position: absolute;
          left: 10px;
          top: 30%;
          border-radius: 50%;
          background-color: #c7b016;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          white-space: nowrap;
          color: #ffffff;
          padding-top: 20px;
          font-size: 18px;
          font-family: "黑体";
          font-weight: bold;
        }

        .staff {
          cursor: pointer;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #f5e995;
          position: absolute;
          font-weight: bold;
          top: 23%;
          left: 58%;
          display: flex;
          color: #9b9252;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.role {
  height: 150px;
  width: 150px;
  // padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.1);

  span {
    font-size: 20px;
    line-height: 40px;
  }
}

::v-deep .el-dialog {
  margin-top: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f9f9f8 !important;
}
</style>
